import React from 'react';
import Helmet from "react-helmet";
import DiscountInclude from '../../components/includes/discountInclude';
import PaymentMethodsInclude from '../../components/includes/paymentMethodsInclude';
import DynamicContent from './dynamicContent';
import AboutOS from './aboutObjectStorage';
import FeatureSlider from './featureSlider';
import ReactDependentScript from "react-dependent-script";

class ObjectStorage extends React.Component {

	render() {

		const richData = {
			"@context": "https://schema.org",
			"@type": "FAQPage",
			"name": "Object Storage in Switzerland",
			"mainEntity": [
			  {
				"@type": "Question",
				"name": "What is Object Storage?",
				"acceptedAnswer": {
				  "@type": "Answer",
				  "text": "Object storage stores data as objects, rather than as files within a hierarchical directory structure. Each object contains data, metadata, and a unique identifier that enables it to be retrieved without knowing its physical location. Object storage is designed to handle large amounts of unstructured data and is a popular choice for cloud-based applications, backup archiving, and big data analytics."
				}
			  },
			  {
				"@type": "Question",
				"name": "Why Object Storage?",
				"acceptedAnswer": {
				  "@type": "Answer",
				  "text": "Object storage offers a range of advantages over hierarchical storage, including scalability, durability, accessibility, and cost-effectiveness. It can store massive amounts of unstructured data and provides a flat address space, making it easy to access data regardless of its physical location. Object storage is also designed to be fault-tolerant and highly available, with data replication and backup features for redundancy."
				}
			  },
			  {
				"@type": "Question",
				"name": "What is the Amazon S3 REST API?",
				"acceptedAnswer": {
				  "@type": "Answer",
				  "text": "The Amazon S3 REST API is an interface supported by object storage that enables easy integration with other S3-compatible applications and services, without causing any disruption to existing workflows. Accessing the storage is a hassle-free process, as any S3-compatible client or library can be utilized to manage it. Once you make an order, we will provide you with access credentials, making it quick and easy to get started with object storage."
				}
			  },
			  {
				"@type": "Question",
				"name": "What is the pricing for Object Storage S3 in Zurich, Switzerland?",
				"acceptedAnswer": {
				  "@type": "Answer",
				  "text": "STORAGE: CHF 0.03 per GB per month; NETWORKING OUT: CHF 0.02 per GB per month; NETWORKING IN: Free; API OPERATIONS: Free; SETUP FEE: Free;"
				}
			  },
			  {
				"@type": "Question",
				"name": "What payment methods are accepted?",
				"acceptedAnswer": {
				  "@type": "Answer",
				  "text": "We accept a variety of most common and convenient payment methods available to this day: VISA, MasterCard, American Express, Discover, PayPal and Bank Wire transfers. In addition to the conventional and widely used payment processors, you can also place an order with crypto and digital currencies: Bitcoin, Bitcoin Cash, Ethereum, Dash, Litecoin, Nextcoin, Tether, Perfect Money, QIWI, WebMoney and other tokens."
				}
			  }
			]
		  }

		return (

			<React.Fragment>
				<Helmet>
					<title>Object Storage in Switzerland | Swiss Made Host</title>
					<meta name="description" content="Secure and highly scalable object storage in Switzerland with S3 compatible API. Store and manage your data with ease while ensuring data durability and accessibility." />
					<link rel="canonical" href="https://swissmade.host/en/object-storage-switzerland" />
					<script type="application/ld+json">{JSON.stringify(richData)}</script>
				</Helmet>
				<div className="fullwidth pic object-storage">
					<div className="row">
						<div className="twelve columns">
							<div className="transparent">
								<h1 className="title">Object Storage <span>in Switzerland</span></h1>
								<h2>Privacy-focused Object Storage in Switzerland with S3-compatible API</h2>
							</div>
						</div>
					</div>
				</div>
				<DiscountInclude />
				<AboutOS/>
				<ReactDependentScript scripts={["/js/jquery-3.4.1.js"]}>
					<FeatureSlider />
				</ReactDependentScript>
				<DynamicContent />
				<PaymentMethodsInclude />
		</React.Fragment >
	)
	}
}

export default ObjectStorage;