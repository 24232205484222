import React from 'react';
import { NavLink } from "react-router-dom";

class PaymentMethodsInclude extends React.Component {

	render() { 
	return (
<div className={this.props.color === 'light' ? 'fullwidth light' : 'fullwidth dark'}>
<div className="row">
	<div className="twelve columns">
		<h2>Payment Methods</h2>
	</div>
</div>
<div className="row">
	<div className="four columns">
		<p>We support debit and credit cards (VISA, MasterCard, American Express), PayPal, Perfect Money, QIWI, WebMoney, and Bank Wire transfers. In addition to these conventional payment methods, we also offer support for a wide variety of crypto and digital currencies. You can place an order using cryptocurrencies such as Bitcoin, Ethereum, Dash, Litecoin, Ripple, Dogecoin, Binance Coin, Solana, Tron, and Tether USDT, among many others. With support for over 100 different payment methods, we strive to provide maximum flexibility for our customers.</p>
		<NavLink className="readmore left" to="/en/accepted-payment-methods">Payment methods <i className="fa-regular fa-square-caret-right"></i></NavLink>
	</div>
	<div className="eight columns payment">
		<div className="row">
		<div className="twelve columns"><span title="VISA payments are accepted here"><i className="fa-brands fa-cc-visa" aria-hidden="true"></i></span>
		<span title="MasterCard payments are accepted here"><i className="fa-brands fa-cc-mastercard" aria-hidden="true"></i></span>
		<span title="American Express payments are accepted here"><i className="fa-brands fa-cc-amex" aria-hidden="true"></i></span>
		<span title="Discover payments are accepted here"><i className="fa-brands fa-cc-discover" aria-hidden="true"></i></span>
		<span title="PayPal payments are accepted here"><i className="fa-brands fa-cc-paypal" aria-hidden="true"></i></span>
		<span title="Bank Wire transfers are accepted here"><i className="fa fa-bank" aria-hidden="true"></i></span></div></div>
		<div className="row"><div className="twelve columns"><span title="Bitcoin payments are accepted here"><i className="cc BTC" title="Bitcoin" aria-hidden="true"></i></span>
		<span title="Bitcoin Cash payments are accepted here"><i className="cc BCH" title="Bitcoin Cash" aria-hidden="true"></i></span>
		<span title="Ethereum payments are accepted here"><i className="cc ETH-alt" title="Ethereum" aria-hidden="true"></i></span>
		<span title="Dash payments are accepted here"><i className="cc DASH" title="DASH" aria-hidden="true"></i></span>
		<span title="Litecoin payments are accepted here"><i className="cc LTC" title="Litecoin" aria-hidden="true"></i></span>
		<span title="Nextcoin payments are accepted here"><i className="cc NXT" title="Nextcoin" aria-hidden="true"></i></span>
		<span title="Tether payments are accepted here"><i className="cc USDT" title="Tether" aria-hidden="true"></i></span></div></div>
		<div className="row"><div className="twelve columns">
		<span title="CoinGate payments are accepted here">
      <img src="/images/payment/cc-cg.png" alt="CoinGate" className="cc-img" title="CoinGate"/>
      </span>
      <span title="Webmoney payments are accepted here">
        <img src="/images/payment/cc-webmoney.png" alt="Webmoney" className="cc-img" title="Webmoney"/>
        </span>
        <span title="QIWI payments are accepted here">
        <img src="/images/payment/cc-qiwi.png" alt="Qiwi" className="cc-img" title="QIWI"/></span>
      <span title="Perfect Money payments are accepted here">
        <img src="/images/payment/cc-pm.png" alt="Perfect Money" title="Perfect Money" className="cc-img"/>
          </span>
		</div></div>
	</div>
</div>
</div>
    )
  }}
  export default PaymentMethodsInclude;
  